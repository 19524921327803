import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const Header = lazy(() => import("./layouts/Header/Header"));
const Footer = lazy(() => import("./layouts/Footer/Footer"));

const Homepage = lazy(() => import("./pages/Homepage/Homepage"));
const TheBook = lazy(() => import("./pages/TheBook/TheBook"));
const About = lazy(() => import("./pages/About/About"));

const App = () => {
  return (
    <div>
      <Suspense fallback={<p>Betöltés folyamatban...</p>}>
        <Header />
        <Routes>
          <Route path="/ismerje-meg-konyvemet" element={<TheBook />} />
          <Route path="/szinopszis" element={<About />} />
          <Route path="/" element={<Homepage />} />
        </Routes>
        <Footer />
      </Suspense>
    </div>
  );
};

export default App;
